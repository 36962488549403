.public-view-page #_act_widget_container {
  display: none; }

.public-view-page #intercom-container {
  display: none; }

.public-view-page .intercom-launcher {
  display: none; }

.public-view-page .navbar.is-public {
  min-height: 6.25rem; }
  .public-view-page .navbar.is-public a.navbar-item {
    color: #fff; }
  .public-view-page .navbar.is-public a.navbar-item.is-link {
    opacity: 0.7;
    text-decoration: none;
    background-color: transparent; }
    .public-view-page .navbar.is-public a.navbar-item.is-link:hover {
      opacity: 1; }
  .public-view-page .navbar.is-public a.navbar-item.is-active {
    opacity: 1; }

.public-view-page .navbar.is-acute {
  background-color: #4400cc; }

.public-view-page .navbar.is-public.is-second {
  display: flex;
  align-items: center;
  justify-content: center; }
  .public-view-page .navbar.is-public.is-second .header {
    color: #fff; }

.public-view-page .aui-modal-navbar {
  justify-content: center; }

.public-view-page .aui-navbar {
  padding: .5em .5em 0;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin-bottom: 1.5rem; }

.public-view-page .aui-modal-navbar li {
  width: unset;
  flex: 0 0 auto;
  margin: 0 1em;
  height: unset; }

.public-view-page .aui-navbar li {
  width: 100%;
  flex: 1 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 40px;
  line-height: 40px;
  color: #fff; }

.public-view-page .aui-navbar li.active .aui-navbar-button {
  background: #fff;
  color: #363636;
  font-weight: 500; }

.public-view-page .aui-modal-navbar li .aui-navbar-button {
  border-radius: 20px;
  padding: 4px 12px;
  color: rgba(255, 255, 255, 0.7); }

.public-view-page .aui-navbar li .aui-navbar-button {
  border-radius: 20px;
  padding: 4px 8px; }

.public-view-page .aui-navbar-button {
  cursor: pointer;
  color: #fff;
  background: none;
  border: none;
  outline: none; }

.public-view-page .add-button {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  min-width: 170px;
  font-weight: 600;
  line-height: 1;
  color: #0059da;
  text-align: center;
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: #0059da;
  border-image: initial;
  border-radius: 20px;
  padding: 6px 12px;
  display: flex; }

.public-view-page .aui__modal__header .aui-searchbar {
  color: rgba(255, 255, 255, 0.7); }

.public-view-page .aui-searchbar {
  position: relative;
  display: flex;
  align-items: center; }

.public-view-page .aui-searchbar input {
  background: transparent;
  border: none;
  outline: none;
  width: calc(100% - 36px);
  margin: 0 10px;
  font-size: 14px;
  padding-right: 16px;
  color: rgba(255, 255, 255, 0.7); }

.public-view-page .aui-searchbar input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.7); }

.public-view-page .aui-searchbar svg {
  fill: rgba(255, 255, 255, 0.7); }

.public-view-page .add-button-white {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  min-width: 170px;
  font-weight: 600;
  line-height: 1;
  color: #4400cc;
  text-align: center;
  background: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #4400cc;
  border-image: initial;
  border-radius: 20px;
  padding: 6px 12px;
  opacity: 0.7;
  display: flex; }

.public-view-page .aui-write-button__icon {
  fill: #4400cc;
  width: 14px;
  margin-right: 6px; }
  .public-view-page .aui-write-button__icon:hover {
    opacity: 1; }

.public-view-page .aui-write-button {
  vertical-align: middle; }
  .public-view-page .aui-write-button:hover {
    opacity: 1; }

.public-view-page .add-link {
  text-decoration: none; }
  .public-view-page .add-link:hover {
    opacity: 1; }

.public-view-page .aui-card__total-comments {
  font-size: 14px;
  color: #9b98b3;
  line-height: 1;
  margin-top: -4px;
  margin-left: 4px;
  font-weight: 500; }

.public-view-page .aui-card__view-comments-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-transition: filter .115s ease-in;
  transition: filter .115s ease-in;
  margin-top: 10px; }
  .public-view-page .aui-card__view-comments-button:hover {
    filter: saturate(20); }

.public-view-page .status-dot-blue {
  height: 8px;
  width: 8px;
  background-color: #3273dc;
  border-radius: 50%;
  margin-right: 6px; }

.public-view-page .status-dot-yellow {
  height: 8px;
  width: 8px;
  background-color: #ffdd57;
  border-radius: 50%;
  margin-right: 6px; }

.public-view-page .status-dot-green {
  height: 8px;
  width: 8px;
  background-color: #48c774;
  border-radius: 50%;
  margin-right: 6px; }

.public-view-page .button.powered {
  background-color: transparent;
  border: 1px solid white;
  height: 27px; }

.public-view-page .error__container {
  color: #f26245;
  margin: 15px 5px; }
