body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 14px;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Overrides */
.bp3-popover-target:focus {
  outline: 0;
  outline-offset: 0;
}

span.bp3-popover-target {
  display: block !important;
}

.bp3-control-indicator {
  outline: 0 !important;
  outline-offset: 0 !important;
}

.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
  background: #4400cc !important;
}

.bp3-control.bp3-switch.price-switch input ~ .bp3-control-indicator {
  background: #4400cc;
}

.bp3-control.bp3-switch.price-switch:hover input ~ .bp3-control-indicator {
  background: #4400cc;
}

.bp3-control .bp3-control-indicator {
  margin-top: -2px !important;
}

:focus {
  outline: none !important;
}